<template>
  <BCard>
    <AppCollapse>
      <AppCollapseItem
        v-for="fixedCampaign in state.content"
        :key="fixedCampaign.uuid"
        :title="getTitle(fixedCampaign)"
      >
        <BRow>
          <BCol cols="12">
            <BFormGroup label="CAMPAIGN_IDX_LIST">
              <BFormTextarea
                v-model="fixedCampaign.idx_list_text"
                rows="5"
              />
            </BFormGroup>

          </BCol>
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="STATE"
            >
              <TagbySelect
                v-model="fixedCampaign.state"
                :options="stateOptions"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="START_AT"
            >
              <TagbyDatetimeInputV3 v-model="fixedCampaign.start_at" />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="END_AT"
            >
              <TagbyDatetimeInputV3 v-model="fixedCampaign.end_at" />
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow class="d-flex justify-content-end">
          <BButton
            class="mr-1"
            variant="outline-danger"
            @click="turnOnDeleteModal(fixedCampaign.uuid)"
          >
            삭제
          </BButton>
        </BRow>
      </AppCollapseItem>
    </AppCollapse>

  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import getters from '../../getters'
import useFixedCampaign from '../../useFixedCampaign'
import useDeleteModal from '../../useDeleteModal'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    TagbyDatetimeInputV3,
    TagbySelect,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    const stateOptions = [
      'ACTIVE',
      'INACTIVE',
    ]

    const {
      getTitle,
    } = useFixedCampaign()

    const {
      turnOnModal: turnOnDeleteModal,
    } = useDeleteModal()

    return {
      state,
      getters,
      stateOptions,
      getTitle,
      turnOnDeleteModal,
    }
  },
})
</script>
